

import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonCol, IonRow, IonSpinner, IonButton, IonIcon,
        IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonCardSubtitle, } from '@ionic/vue';

import LogoImg from '@/components/LogoImg.vue';

// icons
import { logIn, checkmarkCircle } from 'ionicons/icons';

// services
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'ThankYouPage',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
                IonGrid, IonCol, IonRow, IonSpinner, IonButton, IonIcon,
                IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonCardSubtitle,
                LogoImg, },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const paymentType = route.params.paymentType || "";
    const bookingId = route.params.bookingId || "";
    
    const goToMyBookings = async () => {
      router.replace(`/bookings/${bookingId}`);
    }

    return {
      t, goToMyBookings,

      // icons
      logIn, checkmarkCircle,

      // variables
      bookingId, paymentType,
    }
  },
}
