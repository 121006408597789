import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logo_img = _resolveComponent("logo-img")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, { class: "ion-text-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_logo_img)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, { class: "ion-justify-content-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "8",
                    "size-lg": "6",
                    "size-xl": "4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card, { class: "ion-text-center" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_card_header, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                color: "success",
                                size: "large",
                                icon: $setup.checkmarkCircle
                              }, null, 8, ["icon"]),
                              _createVNode(_component_ion_card_title, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString($setup.t('thankYouForYourPayment')), 1)
                                ]),
                                _: 1
                              }),
                              ($setup.paymentType == 'deposit')
                                ? (_openBlock(), _createBlock(_component_ion_card_subtitle, { key: 0 }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.t('confirmedQuotationMsg')), 1)
                                    ]),
                                    _: 1
                                  }))
                                : (_openBlock(), _createBlock(_component_ion_card_subtitle, { key: 1 }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.t('thankYouPaymentDescription')), 1)
                                    ]),
                                    _: 1
                                  }))
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_card_content, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_button, {
                                expand: "block",
                                onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.goToMyBookings()))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString($setup.t('backToMyBookings')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}